import React, { useState, useEffect, useRef } from "react";
import PageTitle from "../Common/PageTitle";
import counteryData from "./countery.json";
import subjectData from "./subject.json";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const AddConsultationForm = () => {
   document.title = "Consultation Form | Education forward, Success Sideways";
  const refContainer = useRef(null);
  const [nationality, setnationaliy] = useState([]);
  const [subjectinterested, setsubjectinterested] = useState();
  const [typeofstudy, setTypeofstudy] = useState([
    "UG",
    "PG",
    "PhD",
    "Foundation",
    "A-level",
    "Diploma",
    "English",
    "Other",
  ]);
  const [consultData, setConsultData] = useState({});
  useEffect(() => {
    setnationaliy(counteryData);
    setsubjectinterested(subjectData);
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setConsultData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleMobileChange = (value) => {
    setConsultData((prevData) => ({
      ...prevData,
      mobile: value,
    }));
  };

  console.log(consultData, "data");
  const submitdata = async (e, v) => {
    e.preventDefault();

    try {
      const api_url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${api_url}/consults/insert`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(consultData),
      });
      if (response.status === 201) {
        Swal.fire({
          title: "success!",
          text: "Consultation Booked Successfully!",
          icon: "success",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      refContainer.current.reset();
    } catch (error) {
      console.log(error, "something went wrong");
    }
  };
  return (
    <>
      <PageTitle title="Free Consultation " />
      <section className="pt-6">
        <div className="container">
          <div className="row mt-n5 align-items-center mr-5  ml-5">
            <div className="col-lg-12 mt-5 wow fadeInUp" data-wow-delay="200ms">
              <div className="pe-lg-5">
                <h2 className="mb-1-9  d-flex justify-content-center align-items-center ">
                  Book Your Free Consultation
                </h2>
                <form
                  className="contact quform"
                  onSubmit={submitdata}
                  method="post"
                  ref={refContainer}
                >
                  <div className="quform-elements">
                    <div className="row justify-content-left align-items-center">
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="name">
                            Your Name <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="fname"
                              type="text"
                              name="fname"
                              onChange={handleInput}
                              required
                              placeholder="Your first name here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="name">
                            Your Last Name{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="lname"
                              type="text"
                              name="lname"
                              onChange={handleInput}
                              required
                              placeholder="Your last  name here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="mobileNumber">Mobile Number:</label>
                          {/* <div className="input-group"> */}
                          {/* <div className="col-md-3">
                              <select
                                className="form-control"
                                id="countryCode"
                                name="countryCode"
                                required
                                onChange={handleInput}
                                // defaultValue={
                                //   nationality && nationality[0]
                                //     ? `${nationality[0].code}${nationality[0].dial_code}`
                                //     : "UK +44"
                                // }
                              >
                                {" "}
                                <option value="">select</option>
                                {nationality &&
                                  nationality.map((data, index) => (
                                    <option
                                      key={index}
                                      value={`${data.code}${data.dial_code}`}
                                    >
                                      {data.code} {data.dial_code}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                id="mobileNumber"
                                name="mobileNumber"
                                maxLength={10}
                                onChange={handleInput}
                                placeholder="Enter your mobile number"
                                required
                                onKeyPress={(event) => {
                                  // Allow only numbers
                                  const charCode = event.which
                                    ? event.which
                                    : event.keyCode;
                                  if (charCode < 48 || charCode > 57) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div> */}
                          <PhoneInput
                            international
                            className="mobileinput  "
                            defaultCountry="GB"
                            name="mobile"
                            id="mobile"
                            value={consultData.mobile0}
                            onChange={handleMobileChange}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Your Email{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              name="email"
                              onChange={handleInput}
                              required
                              placeholder="Your email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Nationality{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <select
                              className="form-select"
                              id="nationality"
                              name="nationality"
                              onChange={handleInput}
                              required
                            >
                              <option value="">Select your nationality</option>
                              {nationality &&
                                nationality.map((data, index) => (
                                  <option key={index} value={data.name}>
                                    {data.name}
                                  </option>
                                ))}
                              {/* Add more options as needed */}
                            </select>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Type Of Study{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <select
                              className="form-select"
                              id="typeofstudy"
                              name="typeofstudy"
                              required
                              onChange={handleInput}
                            >
                              <option value="">Please Select</option>
                              {typeofstudy &&
                                typeofstudy.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              {/* Add more options as needed */}
                            </select>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Year of Study{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <select
                              className="form-select"
                              id="yearofstudy"
                              name="yearofstudy"
                              required
                              onChange={handleInput}
                            >
                              <option value="">Please Select</option>
                              <option value="Apr/May/Jun 2024">
                                Apr/May/Jun 2024
                              </option>
                              <option value="Sep/Oct/Nov 2024">
                                Sep/Oct/Nov 2024
                              </option>
                              <option value="Jan/Feb/Mar 2025">
                                Jan/Feb/Mar 2025
                              </option>
                              <option value="Apr/May/Jun 2025">
                                Apr/May/Jun 2025
                              </option>
                              <option value="Sep/Oct/Nov 2025">
                                Sep/Oct/Nov 2025
                              </option>
                              <option value="Jan/Feb/Mar 2026">
                                Jan/Feb/Mar 2026
                              </option>
                              {/* Add more options as needed */}
                            </select>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Subject Interested{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <select
                              className="form-select"
                              id="subjectint"
                              name="subjectint"
                              onChange={handleInput}
                              required
                            >
                              <option value="">Please Select</option>
                              {subjectinterested &&
                                subjectinterested.map((data, index) => (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                ))}
                              {/* Add more options as needed */}
                            </select>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            How did you hear about EFSS?{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <select
                              // className="form-control"
                              className="  form-select "
                              id="refer"
                              name="refer"
                              required
                              onChange={handleInput}
                            >
                              <option value="">Please Select</option>
                              <option value="Friends">Friends</option>
                              <option value="Social Media">Social Media</option>
                              <option value="Poster">Poster</option>
                              <option value="NEWS Channel">NEWS Channel</option>
                              <option value="News Paper">News Paper</option>
                              <option value="Agent">Agent</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Are You currently living in UK?{" "}
                            <span className="quform-required">*</span>
                          </label>
                          {/* <AvRadioGroup
                            name="articletype"
                            validate={{ required: { value: true } }}
                            errorMessage="Pick one!"
                            className="mt-2"
                          >
                            <div className="col-md-6">
                              <AvRadio label="Upper" value="Upper" />
                            </div>
                            <div className="col-md-6">
                              <AvRadio label="Lower" value="Lower" />
                            </div>
                          </AvRadioGroup> */}
                          <div className="quform-input">
                            <div className="col-md-6">
                              <label className="col-md-6">
                                <input
                                  type="radio"
                                  name="loc"
                                  value="yes"
                                  className="form-check-input form-checked-secondary"
                                  onChange={handleInput}
                                  required
                                />{" "}
                                Yes
                              </label>
                              <label className="col-md-6">
                                {" "}
                                <input
                                  type="radio"
                                  className="form-check-input form-checked-secondary"
                                  name="loc"
                                  value="no"
                                  onChange={handleInput}
                                  required
                                />{" "}
                                No
                              </label>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center ">
                        <div className="quform-element form-group mb-3">
                          <div className="quform-input">
                            <div className="form-check mb-2">
                              <input
                                type="checkbox"
                                value=""
                                className="form-check-input form-checked-secondary"
                                id="primaryChecked"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="primaryChecked"
                              >
                                I hereby consent to receiving digital and
                                telephonic communications from EFSS regarding
                                our application services. I understand that I
                                may change my preferences or opt out at any time
                                by using the unsubscribe link provided in all
                                EFSS email correspondence.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-center align-items-center mt-5">
                        <div className="quform-submit-inner">
                          <button type="submit" className="butn border-none">
                            <span>Book Now</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddConsultationForm;

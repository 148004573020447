import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const Testimonial = () => {
  return (
    <>
      <section
        className="bg-img cover-background"
        style={{ backgroundImage: "url(img/bg/bg-01.jpg)" }}
      >
        <div className="container">
          <div
            className="mb-2-6 mb-lg-6 mx-auto text-center wow fadeIn w-md-80 w-lg-60 w-xl-50"
            data-wow-delay="100ms"
          >
            <span className="text-uppercase text-primary letter-spacing-2 font-weight-800">
              {" "}
              Testimonials{" "}
            </span>
            <h2 className="display-18 display-md-14 display-xl-12 mb-0">
              What Our Student's Say
            </h2>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-lg-9">
              <div className="position-relative">
                <Swiper
                  loop={true}
                  grabCursor={true}
                  spaceBetween={30}
                  autoplay={{
                    delay: 10000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim2.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote mt-1">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        EFSS provided me with their expert guidance,
                        personalized care, and belief in my potential were
                        pivotal in shaping my success. I am profoundly grateful
                        for their invaluable role in my academic journey.
                        Special thanks to Furquan sir for the advice that aided
                        me in acing the preliminary interview, and heartfelt
                        appreciation to the team for efficiently handling the
                        payment confirmation process for the tuition deposit.
                        Their unwavering support has been instrumental, and I
                        extend my sincere thanks for their significant
                        contributions to my educational pursuit.
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Samarjeet
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc International Business
                          <br /> Nottingham Trent University, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim3.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{
                          width: "122px",
                          height: "122px",
                        }}
                      />

                      <div className="testimonial-quote mt-4">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        My consultant proved exceptionally helpful and
                        motivating throughout the entire process. She offered
                        support at every step, even during tight deadlines. I
                        genuinely believe I couldn't have achieved it without
                        her guidance. I enthusiastically recommend EFSS to all
                        my friends with various interests—it's a valuable
                        resource that can make a significant difference.
                      </p>
                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Adarsh
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc International Business and Management
                          <br /> University of Holloway, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim4.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        I express immense gratitude for the steadfast support
                        and guidance offered by the EFSS team throughout my
                        university application journey. Their expert advice and
                        personalized approach facilitated a seamless navigation
                        of complexities. The team's dedication and encouragement
                        significantly bolstered my confidence, making the entire
                        experience less daunting. Timely assistance from EFSS
                        overseas played a pivotal role in securing a spot in my
                        dream college—an accomplishment I attribute to their
                        invaluable help. Their commendable passion for assisting
                        students in achieving their goals shines through in
                        every aspect of their work-Furquan sir stay in touch
                        with his students in UK to provide any post arrival
                        assistance- highly recommend EFSS
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Aditya Chile
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MBA
                          <br /> Nottingham Trent University, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim5.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote mt-4">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        EFSS performed magic for me, they were fantastic
                        throughout the admission process; they reviewed my
                        personal statements and helped me secured a place to a
                        uni where I wanted to go. They also organized airport
                        pickup, sim card among other things after my arrival.
                        Furquan sir - I wholeheartedly endorse your services.
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Tanzil
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          Business Management
                          <br />
                          London Metropoliton University, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim1.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote ">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        I want to express my gratitude to the EFSS Overseas team
                        for their invaluable guidance in the past months.
                        Choosing my career path was a daunting task, but
                        professionalism and in depth knowledge of the UK
                        education system from them proved instrumental to secure
                        a place in top ranked Russel Group university. Our
                        discussions provided comfort, instilled hope, and
                        empowered me to embark on a successful career journey.
                        The counseling session was eye-opening, revealing
                        previously unexplored opportunities. Discovering your
                        insightful guidance was a turning point, and I am deeply
                        indebted for your attention and support. Your positive
                        impact on my life is immeasurable, and I genuinely
                        appreciate the time and effort you dedicated to my
                        growth. Thank you for being a crucial part of my journey
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Laraib Hasan
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc AI and Machine Learning <br /> University of
                          Birmingham , UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim6.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        Opting for the UK as my study destination was a clear
                        choice for several reasons. Having close relatives there
                        prompted my initial visits, and being a fervent sports
                        enthusiast, the UK's prominence in technology activities
                        appealed to me. Seeking a scholarship, I turned to EFSS,
                        who played a pivotal role in securing it for me. I'm
                        delighted to share that with their assistance, I gained
                        admission to Nottingham Trent University with a
                        scholarship, allowing me to happily pursue my dream.
                        EFSS's support has been instrumental in realizing my
                        educational aspirations.
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Uzair
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc Supply Chain Management
                          <br /> NTU, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim7.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote mt-4">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        Kudos to EFSS and Furquan sir! I couldn't have sought
                        better support. Their 24/7 availability, thorough
                        updates, and preparation for every step were
                        instrumental in my journey. I am now studying in my
                        dream Russel group university-
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Azizur Rahman
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc Data Science
                          <br /> University of Birmingham, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim8.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        My motivation to enhance my career in brand management
                        led me to pursue studies abroad. My experience with EFSS
                        has been exceptionally smooth, as their guidance,
                        assistance, and support have left me 100% satisfied.
                        Overcoming a significant hurdle, Furquan and his team
                        from EFSS proved to be a gem. I genuinely appreciate
                        their efforts in aiding me to realize my dream. EFSS's
                        commitment has been instrumental in my successful
                        journey, and I am grateful for their invaluable support.
                        I wholeheartedly recommend them to family, friends and
                        every student who want study abroad
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Darshan Kalange
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          MSc International Business
                          <br /> NTU, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="position-relative">
                      <img
                        src="img/avatars/testtim9.png"
                        className="rounded-circle mb-4"
                        alt="..."
                        style={{ width: "122px", height: "122px" }}
                      />
                      <div className="testimonial-quote">
                        <img src="img/icons/icon-16.png" alt="..." />
                      </div>
                      <p className="mb-4 display-27 display-md-25 display-lg-26">
                        EFSS played a pivotal role in aiding my search for the
                        ideal course and university. The team at EFSS proved
                        exceptionally helpful, well-mannered, and expert in
                        their work. The entire process unfolded seamlessly,
                        thanks to their expertise. I express gratitude to the
                        entire EFSS team for their valuable assistance and
                        guidance throughout the journey. Additionally, the post
                        arrival service is highly commendable, and I recommend
                        exploring it for a comprehensive understanding of
                        available services. My experience with EFSS has been
                        positive, and I encourage others to leverage their
                        services for a smooth and informed academic pursuit.-
                      </p>

                      <h4 className="h5 mb-3 font-weight-600 text-primary">
                        Mohd Saif
                      </h4>
                      <ul className="list-unstyled d-flex align-items-center justify-content-center mb-0">
                        <li className="ms-3">
                          Computer Science
                          <br /> Staffordshire University, UK
                        </li>
                      </ul>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

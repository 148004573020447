import React from 'react'

const ServiceCard = (props) => {
    return (
        <>
            <div className="card card-style03 border-color-light-black serviceCard_css ">
                <div className="card-img position-relative overflow-hidden rounded-top">
                    <img src={props.simg} className="serviceImg_here" alt="..." />
                    <div className="card-icon-img">
                        <img src={props.icon} alt="..." className="w-40px" />
                    </div>
                </div>
                <div className="card-body p-1-6  position-relative">
                    <h3 className="h4 mb-3">  <a href="#">{props.title}</a> </h3>
                    <p className="mb-3">{props.des} </p>
                    <a href="#" className="read-more">
                        <span>Read More</span>
                    </a>
                </div>
            </div>
        </>
    )
}

export default ServiceCard

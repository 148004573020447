import Home from "./Page/Home";
import AllRoutes from "./Routes/AllRoutes";

function App() {
  return (
    <>
     <AllRoutes/>
    </>
  );
}

export default App;

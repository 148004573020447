import React from 'react'
import PageTitle from '../Common/PageTitle'
import ServiceCard from "../Components/Services/ServiceCard"

const Service = () => {
  document.title = "Services | Education forward, Success Sideways"

  return (
    <>
      <PageTitle title="Services" />
      <section>
        <div className="container">
          <div
            className="mb-2-6 mb-lg-6 mx-auto text-center wow fadeIn w-md-80 w-lg-60 w-xl-50"
            data-wow-delay="100ms"
          >
            <span className="text-uppercase text-primary letter-spacing-2 font-weight-800">
              We Provide best Services
            </span>
            <h2 className="display-18 display-md-14 display-xl-12 mb-0">
             Our Services
            </h2>
          </div>
          <div className="row mt-n2-6">
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des=" Our experienced team of education consultants is committed to
                        providing personalized guidance tailored to your unique aspirations
                        and academic background." title="Personalized Guidance" icon="img/icons/icon-23.png"
                simg="https://img.freepik.com/free-vector/decision-making-abstract-concept-illustration-problem-solving-skill-leadership-decision-making-framework-tree-analysis-rational-approach-business-management_335657-52.jpg?size=338&ext=jpg&ga=GA1.1.87170709.1707523200&semt=ais" />

            </div>
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des="Choosing the right university is a crucial decision. We assist you
                                    in selecting institutions that align with your academic and career
                                    goals, ensuring a rewarding and enriching educational experience.
                               " title="University Selection" icon="img/icons/icon-24.png" simg="https://img.freepik.com/premium-vector/students-spending-time-campus-near-college-building_179970-1929.jpg" />

            </div>
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des="Navigating through the application process can be overwhelming. Our
                                    team offers comprehensive support, from preparing your application
                                    documents to ensuring they meet the standards of your chosen
                                    universities." title="Application Support" icon="img/icons/icon-25.png" simg="https://msincanada.in/wp-content/uploads/2020/05/MS-in-Australia-44.png" />

            </div>
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des="Securing a student visa is a crucial step in your journey. We guide
                                    you through the visa application process, providing valuable
                                    insights and assistance to increase your chances of approval." title=" Visa Assistance" icon="img/icons/icon-26.png" simg="https://img.freepik.com/free-vector/online-app-tourism-traveler-with-mobile-phone-passport-booking-buying-plane-ticket_74855-10966.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des="Excelling in standardized tests is key to gaining admission to top
                                    universities. We offer specialized test preparation services,
                                    equipping you with the skills and confidence needed to ace exams
                                    like the TOEFL, IELTS, GRE, and more." title="Test Preparation" icon="img/icons/icon-27.png" simg="https://img.freepik.com/premium-vector/cartoon-young-people-read-books-female-male-student-characters-studying-preparing-exam-readers-sitting_102902-6653.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-4 mt-2-6">
              <ServiceCard des=" We understand the financial aspects of studying abroad. Our team
                                    assists you in exploring scholarship opportunities, financial aid,
                                    and budgeting to make your education dreams financially feasible.
                                " title="Financial Guidance" icon="img/icons/icon-28.png" simg="https://static.vecteezy.com/system/resources/previews/024/775/848/original/financial-advisor-concept-with-businessman-meeting-professional-specialist-for-advice-business-consultant-analyzing-financial-documentation-of-company-flat-cartoon-illustration-isolated-free-vector.jpg"
              />
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Service

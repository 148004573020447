import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "../Common/Navbar";
import Footer from "../Common/Footer";
import Home from "../Page/Home";
import About from "../Page/About";
import Service from "../Page/Service";
import StudyDestination from "../Page/StudyDestination";
import Programs from "../Page/Programs";
import Admission from "../Page/Admission";
import Resource from "../Page/Resource";
import Contact from "../Page/Contact";
import Blog from "../Page/Blog/Blog";
import BlogDetails from "../Page/Blog/BlogDetails";
import Events from "../Page/Events";
import Faq from "../Page/Faq";
import AddConsultationForm from "../Page/AddConsultationForm";

const AllRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/StudyDestination" element={<StudyDestination />} />
          <Route path="/Programs" element={<Programs />} />
          <Route path="/Admission" element={<Admission />} />
          <Route path="/Resource" element={<Resource />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/BlogDetails/:id" element={<BlogDetails />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Faq" element={<Faq />} />
          <Route path="/AddConsultation" element={<AddConsultationForm />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;

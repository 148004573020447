import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <>
      <section className="top-position bg-dark p-0">
        <div className="container-fluid px-0">
          <Swiper
            loop={true}
            grabCursor={true}
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[EffectFade, Autoplay, Navigation, Pagination]}
            className="mySwiper"
          >

            <SwiperSlide>
              <div
                className="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24"
                style={{
                  backgroundImage:
                    "url(https://www.excelsoftcorp.com/wp-content/uploads/sites/4/2023/03/Banner_image_3.jpg)",
                }}
              >
                <div className="container pt-6 pt-md-0">
                  <div className="row align-items-center">
                    <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                      <span className="sm-title text-uppercase text-primary letter-spacing-2 font-weight-800">
                        Study abroad with us!
                      </span>
                      <h2 className="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title">
                        Dream Big, Study Abroad: Where Learning Knows No
                        Borders!
                      </h2>
                      <p className="lead w-95 w-md-90 w-lg-85 mb-2-2">
                        Chase dreams worldwide. Our programs break barriers,
                        fostering boundless learning experiences beyond borders.
                      </p>
                      <Link
                        to="/AddConsultation"
                        className="butn shadow-dark me-2 my-1 my-sm-0"
                      >
                        <span> BOOK A FREE CONSULTATION</span>
                      </Link>
                      <Link
                        to="/About"
                        className="butn white text-secondary-hover shadow-dark my-1 my-sm-0"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div
                className="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24"
                style={{
                  backgroundImage:
                    "url(https://www.careerpathoverses.com/images/slider/slide-7.jpg)",
                }}
              >
                <div className="container pt-6 pt-md-0">
                  <div className="row align-items-center">
                    <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                      <span className="sm-title text-uppercase text-primary letter-spacing-2 font-weight-800">
                        Study abroad with us!
                      </span>
                      <h2 className="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title">
                        Discover, Learn, Grow: Your Journey Begins Here!
                      </h2>
                      <p className="lead w-95 w-md-90 w-lg-85 mb-2-2">
                        Our business consulting providing actionable recommend
                        to force enterprise transformation.
                      </p>
                      <Link
                        to="/AddConsultation"
                        className="butn shadow-dark me-2 my-1 my-sm-0"
                      >
                        <span> BOOK A FREE CONSULTATION</span>
                      </Link>
                      <Link
                        to="/About"
                        className="butn white text-secondary-hover shadow-dark my-1 my-sm-0"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="item bg-img cover-background pt-6 pb-10 pt-sm-6 pb-sm-14 py-md-16 py-lg-20 py-xxl-24"
                style={{ backgroundImage: "url(img/banner/banner-01.jpg)" }}
              >
                <div className="container pt-6 pt-md-0">
                  <div className="row align-items-center">
                    <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-6 position-relative">
                      <span className="sm-title text-uppercase text-primary letter-spacing-2 font-weight-800 wow zoomIn">
                        Study abroad with us!
                      </span>
                      <h1 className="display-17 display-sm-11 display-md-9 display-lg-8 display-xl-4 mb-3 title">
                        Unlock Your Global Potential: Study Abroad Made Easy!
                      </h1>
                      <p className="lead w-95 w-md-90 w-lg-85 mb-2-2">
                        Explore limitless opportunities with our simple study
                        abroad services. From start to finish, we've got you
                        covered!
                      </p>
                      <Link
                        to="/AddConsultation"
                        className="butn shadow-dark me-2 my-1 my-sm-0"
                      >
                        <span> BOOK A FREE CONSULTATION</span>
                      </Link>
                      <Link
                        to="/About"
                        className="butn white text-secondary-hover shadow-dark my-1 my-sm-0"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Slider;

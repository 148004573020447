import React from 'react'

const Vision = () => {
    return (
        <>
            <section className="overflow-visible  about-style-02 " style={{ paddingBottom: 50 }}  >
                <div className="container position-relative z-index-3">
                    <div className="row align-items-center mt-n1-9">
                        <div className="col-lg-6 bgshape_img mt-1-9 wow fadeInUp" data-wow-delay="300ms" >
                            <div className="ps-lg-2-9 position-relative text-center text-sm-start">
                                <img  src="https://influencersedu.com/wp-content/uploads/2020/08/hm-abt.png"  alt="..."  className="rounded" />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp mt-1-9" data-wow-delay="200ms">
                            <div className="pe-2">
                                <div className="mb-2-2 mx-auto wow fadeIn" data-wow-delay="100ms">
                                    <span className="text-uppercase text-primary letter-spacing-2 font-weight-800">
                                        Our Vision
                                    </span>
                                    <h2 className="display-18 display-md-14 display-xl-12 mb-0">
                                        Embark on your educational journey with confidence, supported by
                                        the expertise and dedication of EFSS OVERSEAS UK.{" "}
                                    </h2>
                                </div>
                                <p>
                                    At EFSS Overseas UK, our vision is to empower individuals to achieve
                                    their academic aspirations and broaden their global perspectives
                                    through international education. We envision a world where students
                                    have the opportunity to explore diverse cultures, gain invaluable
                                    skills, and contribute meaningfully to a global society. By
                                    fostering a supportive and inclusive environment, we aim to be the
                                    catalyst for transformative educational experiences that transcend
                                    borders. Our vision is to create lifelong learners who embrace
                                    diversity, adaptability, and innovation, ultimately shaping a
                                    brighter future for themselves and the world. Join us in realizing
                                    this vision and embark on a journey of discovery, growth, and
                                    limitless possibilities with EFSS Overseas UK.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Vision

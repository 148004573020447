import React, { useEffect, useState } from "react";
import PageTitle from "../../Common/PageTitle";
import BlogCard from "./BlogCard";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Blog = () => {
  document.title = "Blog | Education forward, Success Sideways"

  const api_url = process.env.REACT_APP_BASE_URL;
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getblogs();
  }, []);
  const getblogs = async () => {
    try {
      const response = await fetch(`${api_url}/blogs/getblog`);
      const results = await response.json();
      console.log(results, "results");
      setBlogs([...results].reverse());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <PageTitle title="Blog" />
      <section>
        <div className="container">
          <div className="row g-xl-5 mt-n2-6">
            {blogs && blogs.length > 0 ? (
              blogs.map((data, index) => {
                const postdate = moment(data.createdAt);
                const day = postdate.format("DD");
                const month = new Date(data.createdAt).toLocaleDateString(
                  "en-GB",
                  {
                    month: "short",
                  }
                );
                return (
                  <BlogCard
                    key={index}
                    shortDes={data.blog_des}
                    name={data.author}
                    title={data.blog_heading.slice(0, 20)}
                    bimg={data.blogimg}
                    month={month}
                    day={day}
                    delay="200ms"
                    id={data._id}
                  />
                );
              })
            ) : (
              <Skeleton
                height={380}
                count={3}
                style={{ marginLeft: "30px", textAlign: "center" }}
                inline
                width={300}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;

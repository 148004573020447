import React from "react";

const SuccessStory = () => {
  return (
    <>
      <section className="position-relative p-0">
        <div className="container-fluid">
          <div className="row mt-n1-9 wow fadeInUp" data-wow-delay="200ms">
            <div className="col-sm-6 col-lg-3 mt-1-9 p-lg-0">
              <div className="coaching-main">
                <div className="coaching-static-content">
                  <img
                    src="img/coaching/coaching-01.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-09.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Visa Assistance</a>
                    </h3>
                    <p>
                      Securing a student visa is a crucial step in your journey.
                    </p>
                  </div>
                </div>
                <div className="coaching-overlay-content">
                  <img
                    src="img/coaching/coaching-01.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-09.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Visa Assistance</a>
                    </h3>
                    <p>
                      Securing a student visa is a crucial step in your journey.
                      We guide you through the visa application process,
                      providing valuable insights and assistance to increase
                      your chances of approval.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-lg-3 mt-1-9 p-lg-0">
              <div className="coaching-main">
                <div className="coaching-static-content">
                  <img
                    src="img/coaching/coaching-03.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-11.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Test Preparation</a>
                    </h3>
                    <p>
                      Excelling in standardized tests is key to gaining
                      admission to top universities
                    </p>
                  </div>
                </div>
                <div className="coaching-overlay-content">
                  <img
                    src="img/coaching/coaching-03.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-11.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Test Preparation</a>
                    </h3>
                    <p>
                      Excelling in standardized tests is key to gaining
                      admission to top universities. We offer specialized test
                      preparation services, equipping you with the skills and
                      confidence needed to ace exams like the TOEFL, IELTS, GRE,
                      and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-1-9 p-lg-0">
              <div className="coaching-main">
                <div className="coaching-static-content">
                  <img
                    src="img/coaching/coaching-10.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-12.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Financial Guidance</a>
                    </h3>
                    <p>
                      We understand the financial aspects of studying abroad.{" "}
                    </p>
                  </div>
                </div>
                <div className="coaching-overlay-content">
                  <img
                    src="img/coaching/coaching-10.jpg"
                    alt="..."
                    className="coaching-image-3"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-12.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">Financial Guidance</a>
                    </h3>
                    <p>
                      We understand the financial aspects of studying abroad.
                      Our team assists you in exploring scholarship
                      opportunities, financial aid, and budgeting to make your
                      education dreams financially feasible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mt-1-9 p-lg-0">
              <div className="coaching-main">
                <div className="coaching-static-content">
                  <img
                    src="img/coaching/coaching-02.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-10.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">
                        Pre-Departure Orientation
                      </a>
                    </h3>
                    <p>Transitioning to a new country can be challenging. </p>
                  </div>
                </div>
                <div className="coaching-overlay-content">
                  <img
                    src="img/coaching/coaching-02.jpg"
                    alt="..."
                    className="coaching-image-1"
                  />
                  <div className="coaching-content-box dark-box">
                    <div className="coaching-icon-box">
                      <img src="img/icons/icon-10.png" alt="..." />
                    </div>
                    <h3>
                      <a href="#">
                        Pre-Departure Orientation
                      </a>
                    </h3>
                    <p>
                      Transitioning to a new country can be challenging. Our
                      pre-departure orientation equips you with essential
                      information, ensuring a smooth transition to your new
                      academic and cultural environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessStory;

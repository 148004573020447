import React from 'react'

const AboutCard = (props) => {
    return (
        <>
            <div className="col-lg-3  col-xl-3 col-md-6 mt-4 wow fadeInUp" data-wow-delay={props.delay} >
                <div className="why-choose-area  text-center h-100 border border-color-light-black">
                    <img
                        src={props.img} alt="..."
                        className="mb-1-9 mb-xl-5 w-90px"
                    />
                    <h3 className="mb-4 h4">{props.title}</h3>
                    <p className="mb-0">
                        {props.des}
                    </p>
                    <br />
                </div>
            </div>
        </>
    )
}

export default AboutCard

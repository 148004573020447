import React from 'react'
import Slider from '../Components/Slider'
import Service from '../Components/Services/Service'
import SuccessStory from '../Components/SuccessStory'
import Testimonial from '../Components/Testimonial'
import { Link } from 'react-router-dom'

const Home = () => {
    document.title="Education forward, Success Sideways - Home"
    return (
        <>
            {/* <div id="preloader"></div> */}
            <div className="main-wrapper">
               
                <Slider />
                <section className="pb-xxl-10 about-style-01">
                    <div className="container">
                        <div className="row mt-n2-9">
                            <div
                                className="col-lg-6 mt-2-9 mb-18 mb-lg-0 wow fadeIn"
                                data-wow-delay="200ms"
                            >
                                <div className="about-left">
                                    <div className="about-shape-2 ani-top-bottom d-none d-sm-block">
                                        <img src="img/content/shape-02.png" alt="..." />
                                    </div>
                                    <div className="position-relative d-block">
                                        <div className="about-img-1">
                                            <img
                                                src="https://lilacbuds.com/web/landing-images/europe-study-abroad-banner.webp"
                                                alt="..."
                                                className="rounded"
                                            />
                                            <div className="about-shape-1 ani-top-bottom">
                                                <img
                                                    src="img/content/shape-01.png"
                                                    alt="..."
                                                    className="rounded"
                                                />
                                            </div>
                                        </div>
                                        <div className="about-img-2">
                                            <img
                                                src="img/content/about-02.jpg"
                                                alt="..."
                                                className="rounded aboutTwoImg"
                                            />
                                        </div>
                                        <div className="about-icon d-none d-sm-block">
                                            <img
                                                src="img/content/shape-03.png"
                                                alt="..."
                                                className="rounded-circle"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-2-9 wow fadeIn" data-wow-delay="300ms">
                                <div className="ps-xl-3">
                                    <div className="mb-1-6 mx-auto wow fadeIn" data-wow-delay="100ms">
                                        <span className="text-uppercase text-primary letter-spacing-2 font-weight-800 ">
                                            About Us
                                        </span>
                                        <h2 className="display-18 display-md-14 display-xl-12 mb-0">
                                            EFSS Overseas UK: Making your dream of studying abroad come true!
                                        </h2>
                                    </div>
                                    <p className="lead mb-1-6 font-weight-600">
                                        Welcome to EFSS Overseas UK, your trusted partner in realizing your
                                        dreams of international education! At EFSS Overseas UK, we
                                        understand the importance of pursuing education abroad and are
                                        dedicated to guiding you through every step of the process. Whether
                                        you aspire to study in renowned institutions, experience diverse
                                        cultures, or broaden your academic horizons, we are here to make
                                        your journey seamless and successful.
                                    </p>
                                    <Link to="/About" className="butn">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Service />
                <SuccessStory/>
                <Testimonial/>
              
            </div>
        </>
    )
}

export default Home

import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = (props) => {
    
    return (
        <>
            <section class="page-title-section bg-img cover-background" data-overlay-dark="8" style={{ backgroundImage: 'url(/img/pagetitle.jpg)' }}>
                <div class="container">
                    <div class="title-info">
                        <h1 class="display-18 display-md-14 display-xl-12">{props.title}</h1>
                    </div>
                    <div class="breadcrumbs-info">
                        <ul class="ps-0">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to={''}>{props.title}</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageTitle

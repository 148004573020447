import React from 'react'
import AboutCard from '../AboutCard'

const Mission = () => {
    return (
        <>
            <section className="why-choose-style01  here_blue_bgimg bg-dark  " style={{ padding: 0 }}>
                <div className="bgimg_layer_here   " style={{ padding: "100px 0px" }}>
                    <div className="container ">
                        <div className="mb-2-6 mb-lg-6 mx-auto text-center wow fadeIn w-md-80 w-lg-60 w-xl-50" data-wow-delay="100ms" >
                            <span className="text-uppercase text-primary letter-spacing-2 font-weight-800">
                                Our Mission
                            </span>
                            <h2 className="display-18 display-md-14 display-xl-12 mb-0 fs-2 text-light">
                                Why Choose EFSS OVERSEAS UK
                            </h2>
                        </div>
                        <div className="row mt-n4">
                            <AboutCard title="Expertise" des="Benefit from our team of experienced education consultants with
                        in-depth knowledge of international education systems."  img="https://cdn-icons-png.flaticon.com/512/8920/8920621.png" delay="200ms" />
                            <AboutCard title="Personalized Approach" des="  We understand that each student is unique. Our approach is
                    tailored to your specific goals, ensuring a personalized
                    experience." img="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpDMdSS6W4WSb8Ws89XGBtIPG-TjVAtPh7oMETUW-vYMjXVo-49BuHUso7foW_T8DxFnA&usqp=CAU"
                                delay="300ms" />
                            <AboutCard title="Global Network" des="  Our customers can always count on skilled professionals to provide
                    Best To Best services."  img="https://cdn-icons-png.flaticon.com/512/1770/1770053.png" delay="400ms" />

                            <AboutCard title="Success Stories" des=" Our track record of successful placements and satisfied students
                    speaks volumes about the quality of our services."  img="https://cdn.iconscout.com/icon/premium/png-256-thumb/success-stories-2699762-2238676.png?f=webp" delay="500ms"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Mission

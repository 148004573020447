import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <header className="header-style2">
        <div className="navbar-default">
          {/* start top search */}
          <div className="top-search bg-primary">
            <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
              <form
                className="search-form"
                action="search.html"
                method="GET"
                acceptCharset="utf-8"
              >
                <div className="input-group">
                  <span className="input-group-addon cursor-pointer">
                    <button
                      className="search-form_submit fas fa-search text-white"
                      type="submit"
                    />
                  </span>
                  <input
                    type="text"
                    className="search-form_input form-control"
                    name="s"
                    autoComplete="off"
                    placeholder="Type & hit enter..."
                  />
                  <span className="input-group-addon close-search mt-1">
                    <i className="fas fa-times" />
                  </span>
                </div>
              </form>
            </div>
          </div>
          {/* end top search */}
          <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
            <div className="row align-items-center">
              <div className="col-12 col-lg-12">
                <div className="menu_area alt-font">
                  <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="navbar-header navbar-header-custom">
                      {/* start logo */}
                      <Link to="/" className="navbar-brand logodefault">
                        <img id="logo" src="/img/logo.png" alt="logo" />
                      </Link>
                      {/* end logo */}
                    </div>
                    <div className="navbar-toggler bg-primary" />
                    {/* start menu area */}
                    <ul className="navbar-nav ms-auto " id="nav">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/About">About Us</Link>
                      </li>
                      <li>
                        <Link to="/Service">Services</Link>
                      </li>
                      <li>
                        <Link to="/StudyDestination">Study Destinations</Link>
                      </li>
                      <li>
                        <Link to="/Programs">Programs</Link>
                      </li>
                      <li>
                        <Link to="/Admission">Admissions</Link>
                      </li>
                      <li>
                        <Link to="/Resource">Resources</Link>
                      </li>
                      <li>
                        <Link to="/Contact">Contact Us</Link>
                      </li>
                    </ul>
                    {/* end menu area */}
                    {/* start attribute navigation */}
                    <div className="attr-nav align-items-xl-center ms-xl-auto main-font">
                      <ul>
                        {/* <li className="search">
                                                    <a href="#!">
                                                        <i className="fas fa-search" />
                                                    </a>
                                                </li> */}
                        <li className="d-none d-xl-inline-block">
                          <a href="/AddConsultation" className="butn md">
                            <span>Book a free consultation</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* end attribute navigation */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;

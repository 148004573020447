import React from "react";
import { Link } from "react-router-dom";

const BlogCard = (props) => {
  function htmlToString(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");

    // Extract text nodes
    return Array.from(doc.body.childNodes)
      .map((node) => node.textContent)
      .join("");
  }
  return (
    <>
      <div
        className="col-md-6 col-lg-4 mt-2-6 wow fadeInUp"
        data-wow-delay={props.delay}
      >
        <article className="blog-main h-100 rounded border border-color-light-black">
          <div className="blog-image-box position-relative d-block">
            <h2>
              {props.day}
              <span className="d-block text-uppercase display-29">
                {props.month}
              </span>
            </h2>
            <div className="blog-image-01 position-relative d-block overflow-hidden bg-dark rounded-top">
              <a href={`/BlogDetails/${props.id}`}>
                <img
                  src={props.bimg}
                  alt="..."
                  className="rounded-top"
                  style={{
                    width: "400px",
                    height: "200px",
                    objectFit: "contain",
                  }}
                />
              </a>
            </div>
          </div>
          <div className="blog-content position-relative d-block">
            <div className="blog-author position-relative d-block mb-4">
              <h6 className="ps-3 d-inline-block align-items-center mb-1 lh-base position-relative text-uppercase">
                <Link to={`/BlogDetails/${props.id}`}>{props.title}</Link>
              </h6>
              <span className="position-relative d-block">
                Post By: {props.name}
              </span>
            </div>
            <p className="mb-4">
              <Link to={`/BlogDetails/${props.id}`}>
                {htmlToString(props.shortDes).slice(0, 40)}
              </Link>
            </p>
            <div className="row align-items-center">
              <div className="col-8">
                <div className="blog-link">
                  <Link to={`/BlogDetails/${props.id}`}>
                    <span>Read More</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default BlogCard;

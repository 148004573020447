import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    upper();
  }, [location.pathname]);
  const upper = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <footer className="footer-style1 bg-dark">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 mb-1-9 mb-lg-0 wow fadeIn"
              data-wow-delay="200ms"
            >
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src="/img/oldlogo.png"
                    style={{ borderRadius: "10px 5px 10px 5px" }}
                    className="mb-4"
                    alt="..."
                  />
                </Link>
              </div>
              <p className="mb-1-9 text-white w-xxl-85">
                At EFSS Overseas UK, we understand the importance of pursuing
                education abroad and are dedicated to guiding you through every
                step of the process.
              </p>
              <div className="social-icons2">
                <ul className="ps-0 mb-0">
                  <li>
                    <a href="#!">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div
                  className="col-12 mb-1-9 pb-4 border-bottom border-color-light-white wow fadeIn"
                  data-wow-delay="300ms"
                >
                  <div className="row">
                    <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="ti-mobile display-20 text-primary" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h4 className="h5 text-white mb-1">Phone</h4>
                          <a
                            href="tel:+447459611140"
                            className="mb-0 text-white opacity8 small"
                          >
                            +44 7459611140
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="ti-email display-20 text-primary" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h4 className="h5 text-white mb-1">Email</h4>
                          <a
                            href="mailto:info@efssedu.co.uk"
                            className="mb-0 text-white opacity8 small"
                          >
                            info@efssedu.co.uk
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="ti-map-alt display-20 text-primary" />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h4 className="h5 text-white mb-1">Location</h4>
                          <p className="mb-0 text-white opacity8 small">UK</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-sm-6 col-lg-4 mb-1-9 mb-lg-0 wow fadeIn"
                  data-wow-delay="400ms"
                >
                  <h3>About EFSS </h3>
                  <ul className="ps-0 mb-0">
                    <li>
                      <Link to="/About">About Us</Link>
                    </li>
                    <li>
                      <Link to="/Service">Services</Link>
                    </li>
                    <li>
                      <Link to="/Programs">Programs</Link>
                    </li>
                    <li>
                      <Link to="/Admission">Admissions</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-sm-6 col-lg-4 mb-1-9 mb-lg-0 wow fadeIn"
                  data-wow-delay="500ms"
                >
                  <h3>Quick Link</h3>
                  <ul className="ps-0 mb-0">
                    <li>
                      <Link to="/Blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/Faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/Resource">Resources</Link>
                    </li>
                    <li>
                      <Link to="/Events">Events</Link>
                    </li>
                    <li>
                      <Link to="/StudyDestination">Study Destination</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-md-6 col-lg-4 wow fadeIn"
                  data-wow-delay="600ms"
                >
                  <h3>Newsletter</h3>
                  <form
                    className="quform"
                    action="quform/newsletter-two.php"
                    method="post"
                    encType="multipart/form-data"
                    onclick=""
                  >
                    <div className="quform-elements">
                      <div className="row">
                        {/* Begin Text input element */}
                        <div className="col-md-12">
                          <div className="quform-element mb-0">
                            <div className="quform-input">
                              <input
                                className="form-control"
                                id="email_address"
                                type="text"
                                name="email_address"
                                placeholder="Subscribe with us"
                              />
                            </div>
                          </div>
                        </div>
                        {/* End Text input element */}
                        {/* Begin Submit button */}
                        <div className="col-md-12 mt-2">
                          <div className="quform-submit-inner">
                            <button
                              className="butn white-hover text-center border-0 d-block m-0 w-100"
                              type="submit"
                            >
                              Subscribe
                            </button>
                          </div>
                          <div className="quform-loading-wrap text-start mt-3">
                            <span className="quform-loading" />
                          </div>
                        </div>
                        {/* End Submit button */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bar wow fadeIn" data-wow-delay="200ms">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p style={{ fontSize: "14px" }}>
                  Copyright © <span className="current-year" />
                  All Rights Reserved - EFSS | Developed By{" "}
                  <a
                    href="https://onebigbit.com/"
                    target="_blank"
                    className="text-primary text-white-hover"
                  >
                    OneBigBit Technologies Private Limited
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

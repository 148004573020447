import React from 'react'
import PageTitle from '../Common/PageTitle'

const Events = () => {
  document.title = "Events | Education forward, Success Sideways"

  return (
    <>
      <PageTitle title="Events" /> 
      <section className="overflow-visible  about-style-02 " style={{ paddingBottom: 50 }}  >
        <div className="container position-relative z-index-3">
          <div className="row align-items-center mt-n1-9">
            <div className="col-lg-6 wow fadeInUp mt-1-9" data-wow-delay="200ms">
              <div className="pe-2">
                <div className="mb-2-2 mx-auto wow fadeIn" data-wow-delay="100ms">
                  <span className="text-uppercase text-primary letter-spacing-2 font-weight-800">
                    Who We Are
                  </span>
                  <h2 className="display-18 display-md-14 display-xl-12 mb-0">
                    Welcome to EFSS Overseas UK, your trusted partner in realizing
                    your dreams of international education!
                  </h2>
                </div>
                <p>
                  At EFSS Overseas UK, we understand the importance of pursuing
                  education abroad and are dedicated to guiding you through every step
                  of the process. Whether you aspire to study in renowned
                  institutions, experience diverse cultures, or broaden your academic
                  horizons, we are here to make your journey seamless and successful.
                </p>
                <ul className="list-unstyled mb-5">
                  <li>Talk to one of our best consultant today</li>
                  <li>Our experts are able to find new growth</li>
                  <li>Find more information our website</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6  mt-1-9 wow fadeInUp" data-wow-delay="300ms">
              <div className="ps-lg-2-9 position-relative text-center text-sm-start">
                <img
                  src="https://assets.studies-overseas.com/Group_3929_ba1ebb230a.svg"
                  alt="..."
                  className="rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Events

import React, { useState, useEffect } from "react";
import PageTitle from "../../Common/PageTitle";
import { Link, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BlogDetails = () => {
  document.title = "Blog Details | Education forward, Success Sideways";
  const api_url = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [blogdata, setBlogData] = useState();
  const [prevblog, setprevblog] = useState();
  const [nextblog, setnextblog] = useState();
  
  useEffect(() => {
    fetchblog(id);
  }, [id]);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getblogs();
  }, []);
  const fetchblog = async (id) => {
    try {
      const response = await fetch(`${api_url}/blogs/getblogdata/${id}`);
      const data = await response.json();
      setBlogData(data.currentBlog);
      setprevblog(data.previousBlog);
      setnextblog(data.nextBlog);
    } catch (error) {
      console.error("Error fetchingdata:", error.message);
    }
  };

  const getblogs = async () => {
    try {
      const response = await fetch(`${api_url}/blogs/getblog`);
      const results = await response.json();
      const reversedAndSlicedResults = results.reverse().slice(0, 3);
      setBlogs(reversedAndSlicedResults);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <PageTitle title="Blog Details" />
      <section>
        <div className="container">
          <div className="row mt-n2-9">
            <div className="col-lg-8 mt-2-9">
              {blogdata ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="position-relative wow fadeInUp"
                      data-wow-delay="200ms"
                    >
                      <img
                        src={blogdata.blogimg}
                        alt="..."
                        className="rounded-top"
                        style={{
                          width: "100%",
                          height: "500px",
                          objectFit: "contain",
                        }}
                      />
                    </div>

                    <div className="p-1-6 p-sm-1-9 primary-shadow">
                      <div
                        className="mb-1-9 wow fadeInUp"
                        data-wow-delay="200ms"
                      >
                        <h3 className="h2">{blogdata.blog_heading}</h3>
                        <ul className="list-unstyled mb-4">
                          <li className="d-inline-block me-3">
                            <a href="#!" className="display-31">
                              <i className="ti-user me-1 text-primary" />
                              By {blogdata.author}
                            </a>
                          </li>
                          <li className="d-inline-block me-3">
                            <a href="#!" className="display-31">
                              <i className="ti-calendar me-1 text-primary" />{" "}
                              {new Date(blogdata.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </a>
                          </li>
                        </ul>
                        <p className="mb-0">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: blogdata && blogdata.blog_des,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Skeleton count={1} duration={2} height={500} />
                  <Skeleton count={1} duration={2} height={50} />
                  <Skeleton count={1} duration={2} />
                  <Skeleton count={1} duration={2} height={50} />
                </>
              )}
              <div className="wow fadeInUp" data-wow-delay="200ms">
                <div className="page-navigation mb-6 mt-2-9">
                  {prevblog ? (
                    <div className="prev-page">
                      <div className="page-info">
                        <Link to={`/BlogDetails/${prevblog._id}`}>
                          <span className="image-prev">
                            <img
                              src={prevblog.blogimg}
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                              alt="..."
                            />
                          </span>
                          <div className="prev-link-page-info">
                            <h4 className="prev-title">
                              {prevblog.blog_heading}
                            </h4>
                            <span className="date-details">
                              <span className="create-date">
                                {" "}
                                {new Date(
                                  prevblog.createdAt
                                ).toLocaleDateString("en-GB", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                              </span>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <Skeleton width={350} height={110} count={1} duration={2} />
                  )}
                  <div className="next-page">
                    <div className="page-info">
                      {nextblog ? (
                        <Link to={`/BlogDetails/${nextblog._id}`}>
                          <div className="next-link-page-info">
                            <h4 className="next-title">
                              {nextblog.blog_heading}
                            </h4>
                            <span className="date-details">
                              <span className="create-date">
                                {new Date(
                                  nextblog.createdAt
                                ).toLocaleDateString("en-GB", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                              </span>
                            </span>
                          </div>
                          <span className="image-next">
                            <img
                              src={nextblog.blogimg}
                              style={{
                                width: "60px",
                                height: "60px",
                                objectFit: "contain",
                              }}
                              alt="..."
                            />
                          </span>
                        </Link>
                      ) : (
                        <Skeleton
                          width={350}
                          height={110}
                          count={1}
                          duration={2}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-2-9">
              <div className="sidebar ps-xl-4">
                <div className="widget wow fadeInUp" data-wow-delay="200ms">
                  <div className="widget-content">
                    <div className="widget-title">
                      <h3 className="text-uppercase text-primary letter-spacing-2 font-weight-800 small">
                        Recent Posts
                      </h3>
                    </div>
                    {blogs &&
                      blogs.map((data, index) => (
                        <div className="d-flex mt-4 recent-post align-items-start align-items-sm-center align-items-lg-start">
                          <div className="flex-shrink-0 me-3">
                            <Link to={`/BlogDetails/${data._id}`}>
                              <img
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "contain",
                                }}
                                src={data.blogimg}
                                alt="..."
                              />
                            </Link>
                          </div>
                          <div className="flex-grow-1">
                            <span className="display-30 text-muted d-block mb-1 font-weight-500">
                              <i className="far fa-calendar-check me-2 text-primary" />
                              {new Date(data.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                }
                              )}
                            </span>
                            <h4 className="h6 mb-0">
                              <Link to={`/BlogDetails/${data._id}`}>
                                {data.blog_heading}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div
                  className="widget bg-secondary rounded wow fadeInUp"
                  data-wow-delay="200ms"
                >
                  <div className="banner-wrapper rounded">
                    <img
                      src="/img/content/countries-sidebar-01.jpg"
                      className="rounded-top"
                      alt="..."
                    />
                    <div className="p-2-2 position-relative rounded-bottom bg-secondary text-center">
                      <div className="icon-boxs bg-primary">
                        <i className="ti-headphone-alt fs-1 text-white" />
                      </div>
                      <h4 className="mb-3 text-white">How Can We Help?</h4>
                      <ul className="text-center list-unstyled mb-0">
                        <li className="text-white mb-2">
                          <i className="fa fa-phone small text-white me-2" />
                          <a href="tel:+447459611140" className="text-white">
                            +44 7459611140
                          </a>
                        </li>
                        <li className="text-white">
                          <i className="fa fa-envelope-open small text-white me-2" />
                          <a
                            href="mailto:info@efssedu.co.uk"
                            className="text-white"
                          >
                            info@efssedu.co.uk
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;

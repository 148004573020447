import React, { useState, useRef } from "react";
import PageTitle from "../Common/PageTitle";
import Swal from "sweetalert2";
const Contact = () => {
  document.title = "Contacts | Education forward, Success Sideways";
  const refContainer = useRef(null);
  const [Data, setData] = useState({});
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log(Data, "data");
  const submitdata = async (e, v) => {
    e.preventDefault();

    try {
      const api_url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${api_url}/contacts/insert`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      });
      if (response.status === 201) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: "Message send successfully",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      refContainer.current.reset();
    } catch (error) {
      console.log(error, "something went wrong");
    }
  };
  return (
    <>
      <PageTitle title="Contact Us" />
      <section>
        <div className="container">
          <div className="row mt-n1-9 gx-xxl-5">
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="contact-info-block h-100">
                <div
                  className="contact-img position-relative bg-img"
                  data-background="img/content/contact-01.jpg"
                >
                  <div className="bg-overlay" />
                  <div className="content-text">
                    <i className="fas fa-phone-alt display-15 mb-3 d-block" />
                    <h3 className="h4 mb-3">Phone</h3>
                    <p className="mb-1">
                      {" "}
                      <a href="tel:+447459611140">+44 7459611140</a>
                    </p>
                    {/* <p className="mb-0">+91 1111111111</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="contact-info-block h-100">
                <div
                  className="contact-img position-relative bg-img"
                  data-background="img/content/contact-02.jpg"
                >
                  <div className="bg-overlay" />
                  <div className="content-text">
                    <i className="fas fa-map-marker-alt display-15 mb-3 d-block" />
                    <h3 className="h4 mb-3">Location</h3>
                    <p className="mb-0">UK</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 mt-1-9 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="contact-info-block h-100">
                <div
                  className="contact-img position-relative bg-img"
                  data-background="img/content/contact-03.jpg"
                >
                  <div className="bg-overlay" />
                  <div className="content-text">
                    <i className="far fa-envelope display-15 mb-3 d-block" />
                    <h3 className="h4 mb-3">Email</h3>
                    <p className="mb-1">
                      {" "}
                      <a href="mailto:info@efssedu.co.uk">info@efssedu.co.uk</a>
                    </p>
                    {/* <p className="mb-0">info@abc123.com</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="row mt-n5 align-items-center">
            <div className="col-lg-6 mt-5 wow fadeInUp" data-wow-delay="200ms">
              <div className="pe-lg-5">
                <h2 className="mb-1-9">Send Us a Message</h2>
                <form
                  className="contact quform"
                  onSubmit={submitdata}
                  method="post"
                  ref={refContainer}
                >
                  <div className="quform-elements">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="name">
                            Your Name <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="name"
                              type="text"
                              name="name"
                              required
                              onChange={handleInput}
                              placeholder="Your name here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Your Email{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              name="email"
                              required
                              onChange={handleInput}
                              placeholder="Your email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="subject">
                            Your Subject{" "}
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="subject"
                              type="text"
                              name="subject"
                              required
                              onChange={handleInput}
                              placeholder="Your subject here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="message">
                            Message <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <textarea
                              className="form-control h-100"
                              id="message"
                              name="message"
                              required
                              onChange={handleInput}
                              rows={6}
                              placeholder="Tell us a few words"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-2">
                        <div className="quform-submit-inner">
                          <button type="submit" className="butn border-none">
                            <span>Send Now</span>
                          </button>
                        </div>
                        <div className="quform-loading-wrap text-start">
                          <span className="quform-loading" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-lg-6 mt-5 wow fadeInUp d-none d-lg-block"
              data-wow-delay="400ms"
            >
              <div>
                <img
                  src="https://avit.ac.in/wp-content/uploads/2023/11/contactadmission.webp"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
